import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-news-reviews',
  templateUrl: './news-reviews.component.html',
  styleUrls: ['./news-reviews.component.scss']
})
export class NewsReviewsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
