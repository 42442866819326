import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

// rxjs
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';

// service
import { HomeService } from 'src/app/services/home.service';
import { LoadingScreenService } from 'src/app/services/loading-screen.service';

@Component({
  selector: 'app-why-choose',
  templateUrl: './why-choose.component.html',
  styleUrls: ['./why-choose.component.scss']
})
export class WhyChooseComponent implements OnInit {
  dataSubscription: Subscription;
  totalVehicles: number;
  totalDealerships: number;
  totalBrands: number;

  constructor(private router: Router,
              private loadingScreenService: LoadingScreenService,
              private homeService: HomeService) { }

  ngOnInit() {
    this.loadingScreenService.show();
    this.dataSubscription = this.homeService.getWhyChooseUs()
      .pipe(first())
      .subscribe(
        (data: any) => {
          if (data.result) {
            this.totalVehicles = data.result.totalVehicles;
            this.totalDealerships = data.result.totalDealerships;
            this.totalBrands = data.result.totalBrands;
          }
        },
        () => {
          this.loadingScreenService.hide();
        });
  }

}
