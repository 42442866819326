import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { SliderType } from 'igniteui-angular';
import { SearchResultService } from 'src/app/services/search-result.service';
import { Subscription } from 'rxjs';
import { LoadingScreenService } from 'src/app/services/loading-screen.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss']
})
export class FiltersComponent implements OnInit {
  searchResultForm: FormGroup;

  public sliderType = SliderType;
  public priceRange: PriceRange = new PriceRange(0, 5000);
  public priceRange2: PriceRange = new PriceRange(0, 5000);
  public mileageRange: PriceRange = new PriceRange(0, 5000);
  public priceRange4: PriceRange = new PriceRange(0, 5000);

  lowerPrice = 100;
  uperPrice = 1000;

  dataSubscription: Subscription;
  filters: any;
  bodyTypeItems: FormArray;
  order: any;

  vehicleStockType: any;
  make: any;
  model: any;
  bodyTypes: any;
  minPrice: any;
  maxPrice: any;
  province: any;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private loadingScreenService: LoadingScreenService,
    private searchResultService: SearchResultService
  ) { }

  @Output()
  onFilterClick: EventEmitter<boolean> = new EventEmitter<boolean>();

  public updatePriceRange(event) {
    // const prevPriceRange = this.priceRange;
    // switch (event.id) {
    //   case "lowerInput": {
    //     if (!isNaN(parseInt(event.value, 100))) {
    //       this.priceRange = new PriceRange(event.value, prevPriceRange.upper);
    //     }
    //     break;
    //   }
    //   case "upperInput": {
    //     if (!isNaN(parseInt(event.value, 100))) {
    //       this.priceRange = new PriceRange(prevPriceRange.lower, event.value);
    //     }
    //     break;
    //   }
    // }
  }

  ngOnInit() {

    this.searchResultForm = this.formBuilder.group({
      vehicleStockType: [0],
      make: [null],
      model: [null],
      province: [null],
      dealership: [null],
      bodyTypeItems: this.formBuilder.array([this.createBodyTypeItem()]),
      exteriorColours: [null],
      transmission: [null],
      powertrain: [null],
      driveType: [null]
    });

    this.route.queryParamMap.subscribe(params => {
      this.vehicleStockType = params.get('searchVehicleType');
      this.make = params.get('make');
      this.model = params.get('model');
      this.bodyTypes = params.get('bodyType');
      this.minPrice = params.get('minPrice');
      this.maxPrice = params.get('maxPrice');
      this.province = params.get('province');
    });

    this.loadingScreenService.show();
    this.getFilters();
  }

  createBodyTypeItem(): FormGroup {
    return this.formBuilder.group({
      id: [null]
    });
  }

  showSearch() {
    this.onFilterClick.emit(true);
  }

  getFilters() {
    this.dataSubscription = this.searchResultService.getSideBarFilters()
      .subscribe(
        (res: any) => {
          this.filters = res.result;
          console.log(this.filters);

          if (this.filters.priceFilter && this.filters.priceFilter.length > 0 ) {
               const price = this.filters.priceFilter[0];
               this.priceRange = new PriceRange(price.min_val, price.max_val);
          }

          if (this.filters.mileageFilter && this.filters.mileageFilter.length > 0) {
            const mileage = this.filters.mileageFilter[0];
            this.mileageRange = new PriceRange(mileage.min_mileage, mileage.max_mileage);
          }

          const vehicleMake = this.filters.vehicleMake.find(t => t.id === Number(this.make));
          const model = this.filters.vehicleModel.find(t => t.id === Number(this.model));
          const province = this.filters.provinceList.find(t => t.id === Number(this.province));

          let filterBodyTypePlaceHolders = this.bodyTypes.split(',').length - 1;
          while (filterBodyTypePlaceHolders >= 1) {
            this.addAnotherBodyType();
            filterBodyTypePlaceHolders--;
          }

          const bodyTypeIds = this.bodyTypes.split(',');
          const intBodyTypeIds = [];
          bodyTypeIds.forEach(element => {
            intBodyTypeIds.push({id:Number(element)});
          });

          this.searchResultForm.patchValue({
            vehicleStockType: Number(this.vehicleStockType),
            make: vehicleMake.id,
            model: model.id,
            province: province.id,
            bodyTypeItems: intBodyTypeIds
          });

        },
        error => {
           this.loadingScreenService.hide();
        });
  }

  addAnotherBodyType() {
    this.bodyTypeItems = this.searchResultForm.get('bodyTypeItems') as FormArray;
    this.bodyTypeItems.push(this.createBodyTypeItem());
  }

  deleteBodyTypeItem(i) {
    this.bodyTypeItems = this.searchResultForm.get('bodyTypeItems') as FormArray;
    this.bodyTypeItems.removeAt(i);
  }

  // tslint:disable-next-line:no-angle-bracket-type-assertion
  get filterBodyTypesData() { return <FormArray> this.searchResultForm.get('bodyTypeItems'); }

}

class PriceRange {
  constructor( public lower: number, public upper: number) {}
}
