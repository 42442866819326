import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

// rxjs
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';

// service
import { LoadingScreenService } from 'src/app/services/loading-screen.service';
import { HomeService } from 'src/app/services/home.service';

@Component({
  selector: 'app-search-by-type',
  templateUrl: './search-by-type.component.html',
  styleUrls: ['./search-by-type.component.scss']
})
export class SearchByTypeComponent implements OnInit {
  dataSubscription: Subscription;
  bodyTypes: any;
  vehicleType: any;

  constructor(private router: Router,
              private loadingScreenService: LoadingScreenService,
              private homeService: HomeService) {
    this.getList();
  }

  @Input()
  public set VehicleType(value: any) {
    if (!value) {
      this.vehicleType = 0;
    } else {
      this.vehicleType = value;
    }
  }

  ngOnInit() {
  }

  getList() {
    this.loadingScreenService.show();
    this.dataSubscription = this.homeService.getBodyTypeList()
      .pipe(first())
      .subscribe(
        (res: any) => {
          this.bodyTypes = res.result;
        },
        () => {
          this.loadingScreenService.hide();
        });
  }

}
