import { Component, OnInit } from '@angular/core';
import { NgxGalleryOptions, NgxGalleryImage, NgxGalleryAnimation } from 'ngx-gallery';

@Component({
  selector: 'app-new-cars-slider',
  templateUrl: './new-cars-slider.component.html',
  styleUrls: ['./new-cars-slider.component.scss']
})
export class NewCarsSliderComponent implements OnInit {
  galleryOptions: NgxGalleryOptions[];
  galleryImages: NgxGalleryImage[];
  constructor() { }

  ngOnInit(): void {
 
    this.galleryOptions = [
        {
            width: '80%',
            height: '692px',
            imagePercent: 90,
            thumbnailsPercent: 10,
            thumbnailsMargin: 0,
            thumbnailMargin: 0,
            thumbnailsColumns: 9,
            imageAnimation: NgxGalleryAnimation.Slide
        },
        // max-width 800
        {
          breakpoint: 800,
          width: '80%',
          height: '492px',
          imagePercent: 85,
          thumbnailsPercent: 15,
          thumbnailsMargin: 0,
          thumbnailMargin: 0,
          thumbnailsColumns: 5,
          imageAnimation: NgxGalleryAnimation.Slide
        },
        // max-width 767
        {
          breakpoint: 767,
          width: '100%',
          height: '280px',
          imagePercent: 85,
          thumbnailsPercent: 15,
          thumbnailsMargin: 0,
          thumbnailMargin: 0,
          thumbnailsColumns: 5,
          imageAnimation: NgxGalleryAnimation.Slide
        },
        // max-width 400
        {
            breakpoint: 400,
            preview: true
        }
    ];

    this.galleryImages = [
        {
            small: 'assets/images/img-showroom-preview-1.jpg',
            medium: 'assets/images/img-showroom-preview-1.jpg',
            big: 'assets/images/img-showroom-preview-1.jpg'
        },
        {
          small: 'assets/images/img-showroom-preview-1.jpg',
          medium: 'assets/images/img-showroom-preview-1.jpg',
          big: 'assets/images/img-showroom-preview-1.jpg'
        },
        {
          small: 'assets/images/img-showroom-preview-1.jpg',
          medium: 'assets/images/img-showroom-preview-1.jpg',
          big: 'assets/images/img-showroom-preview-1.jpg'
        },
        {
          small: 'assets/images/img-showroom-preview-1.jpg',
          medium: 'assets/images/img-showroom-preview-1.jpg',
          big: 'assets/images/img-showroom-preview-1.jpg'
      },
      {
        small: 'assets/images/img-showroom-preview-1.jpg',
        medium: 'assets/images/img-showroom-preview-1.jpg',
        big: 'assets/images/img-showroom-preview-1.jpg'
      },
      {
        small: 'assets/images/img-showroom-preview-1.jpg',
        medium: 'assets/images/img-showroom-preview-1.jpg',
        big: 'assets/images/img-showroom-preview-1.jpg'
      }
    ];
}

  
}
