import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-home-body-contents",
  templateUrl: "./home-body-contents.component.html",
  styleUrls: ["./home-body-contents.component.scss"]
})
export class HomeBodyContentsComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
