import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

// rxjs
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';

// service
import { LoadingScreenService } from 'src/app/services/loading-screen.service';
import { HomeService } from 'src/app/services/home.service';

@Component({
  selector: 'app-popular-search',
  templateUrl: './popular-search.component.html',
  styleUrls: ['./popular-search.component.scss']
})
export class PopularSearchComponent implements OnInit {
  locations: any = [];
  brands: any = [];
  dataSubscription: Subscription;

  constructor(private router: Router,
              private loadingScreenService: LoadingScreenService,
              private homeService: HomeService) {
                this.getListData();
              }

  ngOnInit() {
  }

  getListData() {
    this.loadingScreenService.show();
    this.dataSubscription = this.homeService.getProvinces()
      .pipe(first())
      .subscribe(
        (res: any) => {
          this.locations = res.result;
        },
        () => {
          this.loadingScreenService.hide();
        });

    this.dataSubscription = this.homeService.getMakeList()
      .pipe(first())
      .subscribe(
        (res: any) => {
          this.brands = res.result;
        },
        () => {
          this.loadingScreenService.hide();
        });
  }
}
