import { Component, OnInit, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-new',
  templateUrl: './new.component.html',
  styleUrls: ['./new.component.scss']
})
export class NewComponent implements OnInit {
   showBrands = false;
   showSpecials = false;
   hideOnClick = true;
   shownewCars = false;


  constructor() {
    this.showBrands = false;
    this.showSpecials = false;
    this.hideOnClick = true;
  }

  @Output()
  onNewMobClick: EventEmitter<boolean> = new EventEmitter<boolean>();

  ngOnInit() {
  }

  showBrand() {
    this.showBrands = !this.showBrands;
    this.showSpecials = false;
    this.hideOnClick = !this.hideOnClick;
  }

  showSpecial() {
    this.showBrands = false;
    this.showSpecials = !this.showSpecials;
    this.hideOnClick = !this.hideOnClick;
  }

  backToNav() {
    this.hideOnClick = false;
    this.onNewMobClick.emit(true);
  }

  backToNewNav() {
    this.showBrands = false;
    this.showSpecials = false;
    this.hideOnClick = true;
  }
}
