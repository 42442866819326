import { Component, OnInit } from '@angular/core';
import {coerceNumberProperty} from '@angular/cdk/coercion';

@Component({
  selector: 'app-used-cars-details',
  templateUrl: './used-cars-details.component.html',
  styleUrls: ['./used-cars-details.component.scss']
})
export class UsedCarsDetailsComponent implements OnInit {
  isShowMoreActive = false;

  autoTicks = false;
  disabled = false;
  invert = false;
  max = 100;
  min = 0;
  showTicks = false;
  step = 1;
  thumbLabel = false;
  value = 0;
  vertical = false;

  get tickInterval(): number | 'auto' {
    return this.showTicks ? (this.autoTicks ? 'auto' : this._tickInterval) : 0;
  }
  set tickInterval(value) {
    this._tickInterval = coerceNumberProperty(value);
  }
  private _tickInterval = 1;

  constructor() { }

  ngOnInit() {
  }
  expandShowMore() {
    this.isShowMoreActive = !this.isShowMoreActive;
  }

}
