import { Injectable } from '@angular/core';

// rxjs
import { map } from 'rxjs/operators';

// Config
import { AppConfig } from '../app.config';

// service
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  constructor(private dataService: DataService) { }

  getWhyChooseUs() {
    const request = {
      path: AppConfig.Api.Home.WhyChooseUs
    };

    return this.dataService.get(request).pipe(
      map((res) =>  {
         return res;
        }
      ));
  }

  getStockTypeList() {
    const request = {
      path: AppConfig.Api.Home.StockTypeList
    };

    return this.dataService.get(request).pipe(
      map((res) =>  {
         return res;
        }
      ));
  }

  getBodyTypeList() {
    const request = {
      path: AppConfig.Api.Home.BodyTypeList
    };

    return this.dataService.get(request).pipe(
      map((res) =>  {
         return res;
        }
      ));
  }

  getMakeList() {
    const request = {
      path: AppConfig.Api.Home.MakeList
    };

    return this.dataService.get(request).pipe(
      map((res) =>  {
         return res;
        }
      ));
  }

  getModelList() {
    const request = {
      path: AppConfig.Api.Home.ModelList
    };

    return this.dataService.get(request).pipe(
      map((res) =>  {
         return res;
        }
      ));
  }

  getProvinces() {
    const request = {
      path: AppConfig.Api.Home.Provinces
    };

    return this.dataService.get(request).pipe(
      map((res) =>  {
         return res;
        }
      ));
  }
}
