import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-new-car-banner',
  templateUrl: './new-car-banner.component.html',
  styleUrls: ['./new-car-banner.component.scss']
})
export class NewCarBannerComponent implements OnInit {
  isShowMoreActive = false;
  constructor() { }

  ngOnInit() {
  }

  expandShowMore() {
    this.isShowMoreActive = !this.isShowMoreActive;
  }

}
