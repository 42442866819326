import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-slider-specials',
  templateUrl: './slider-specials.component.html',
  styleUrls: ['./slider-specials.component.scss']
})
export class SliderSpecialsComponent implements OnInit {
  constructor() {}

  title = '';

  carouselOptions = {
    margin: 25,
    nav: true,
    navText: [
      '<div class=\'nav-btn prev-slide\'></div>',
      '<div class=\'nav-btn next-slide\'></div>'
    ],
    responsiveClass: true,
    responsive: {
      0: {
        items: 1,
        nav: true
      },
      600: {
        items: 1,
        nav: true
      },
      678: {
        items: 3,
        nav: true
      },
      1000: {
        items: 3,
        nav: true,
        loop: false
      },
      1500: {
        items: 3,
        nav: true,
        loop: false
      }
    }
  };

  images = [
    {
      text: 'Everfresh Flowers',
      image: '../../../assets/images/carnation/expression-v2.jpeg'
    },
    {
      text: 'Festive Deer',
      image: '../../../assets/images/spec-home-img-2.png'
    },
    {
      text: 'Morning Greens',
      image: '../../../assets/images/spec-home-img-3.png'
    },
    {
      text: 'Bunch of Love',
      image: '../../../assets/images/carnation/expression-v2.jpeg'
    },
    {
      text: 'Blue Clear',
      image: '../../../assets/images/spec-home-img-2.png'
    },
    {
      text: 'Evening Clouds',
      image: '../../../assets/images/spec-home-img-3.png'
    },
    {
      text: 'Fontains in Shadows',
      image: '../../../assets/images/carnation/expression-v2.jpeg'
    },
    {
      text: 'Kites in the Sky',
      image: '../../../assets/images/spec-home-img-2.png'
    }
  ];

  ngOnInit() {}
}
