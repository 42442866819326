import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { Ng5SliderModule } from 'ng5-slider';

import { AppRoutingModule } from './app-routing.module';

// multiselect-dropdown
import { NgMultiSelectDropDownModule } from 'src/app/components/ng-multiselect-dropdown/src/ng-multiselect-dropdown.module';

import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { MaterialModule } from 'src/app/core/material/material.module';
import { OwlModule } from 'ngx-owl-carousel';
import { NgxGalleryModule } from 'ngx-gallery';

import { NewComponent } from './components/meganav/new/new.component';
import { UsedComponent } from './components/meganav/used/used.component';
import { SpecialsComponent } from './components/meganav/specials/specials.component';
import { FooterComponent } from './components/footer/footer.component';
import { SearchByBrandComponent } from './components/meganav/search-by-brand/search-by-brand.component';
import { NewCarSpecialsComponent } from './components/meganav/new-car-specials/new-car-specials.component';
import { SearchByPriceComponent } from './components/meganav/search-by-price/search-by-price.component';
import { SearchByTypeComponent } from './components/meganav/search-by-type/search-by-type.component';
import { TabBottomLinkComponent } from './components/meganav/tab-bottom-link/tab-bottom-link.component';
import { SearchResultsComponent } from './components/search-results/search-results.component';
import { FiltersComponent } from './components/search-results/filters/filters.component';
import { SearchResultListComponent } from './components/search-results/search-result-list/search-result-list.component';
import { FindNewTodayComponent } from './components/home-body-contents/find-new-today/find-new-today.component';
import { ShowroomComponent } from './components/showroom/showroom.component';
import { HomeComponent } from './components/home/home.component';
import { ShowroomBannerComponent } from './components/showroom/showroom-banner/showroom-banner.component';
import { ShowroomModelsComponent } from './components/showroom/showroom-models/showroom-models.component';
import { IgxInputGroupModule, IgxSliderModule } from 'igniteui-angular';
import { ShowroomMapComponent } from './components/showroom/showroom-map/showroom-map.component';
import { ShowroomBrowseDealershipComponent } from './components/showroom/showroom-browse-dealership/showroom-browse-dealership.component';
import { HomeBannerComponent } from './components/home-banner/home-banner.component';
import { SearchAllComponent } from './components/home-banner/search-all/search-all.component';
import { HomeBodyContentsComponent } from './components/home-body-contents/home-body-contents.component';
import { SliderSpecialsComponent } from './components/home-body-contents/slider-specials/slider-specials.component';
import { SliderTrendingComponent } from './components/home-body-contents/slider-trending/slider-trending.component';
import { PopularBrandsComponent } from './components/home-body-contents/popular-brands/popular-brands.component';
import { WhyChooseComponent } from './components/home-body-contents/why-choose/why-choose.component';
import { NewsReviewsComponent } from './components/home-body-contents/news-reviews/news-reviews.component';
import { PopularSearchComponent } from './components/home-body-contents/popular-search/popular-search.component';
import { NewCarsDetailsComponent } from './components/new-cars-details/new-cars-details.component';
import { NewCarBannerComponent } from './components/new-cars-details/new-car-banner/new-car-banner.component';
import { EnquiryFormComponent } from './components/enquiry-form/enquiry-form.component';
import { NewCarsSliderComponent } from './components/new-cars-details/new-cars-slider/new-cars-slider.component';
import { UsedCarsDetailsComponent } from './components/used-cars-details/used-cars-details.component';
import { UsedImageGalleryComponent } from './components/used-cars-details/used-image-gallery/used-image-gallery.component';
import { SliderSimilarUsedCarsComponent } from './components/new-cars-details/slider-similar-used-cars/slider-similar-used-cars.component';
import { SliderPeopleViewedComponent } from './components/new-cars-details/slider-people-viewed/slider-people-viewed.component';
import { EstimationCalculationComponent } from './components/used-cars-details/estimation-calculation/estimation-calculation.component';
import { FindDealerComponent } from './components/find-dealer/find-dealer.component';
import { BrowseByDealershipComponent } from './components/browse-by-dealership/browse-by-dealership.component';
import { DetailsComponent } from './components/find-dealer/details/details.component';
import { ListingComponent } from './components/find-dealer/listing/listing.component';
import { LoadingScreenComponent } from './components/shared/loading-screen/loading-screen.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    NewComponent,
    UsedComponent,
    SpecialsComponent,
    FooterComponent,
    SearchByBrandComponent,
    NewCarSpecialsComponent,
    SearchByPriceComponent,
    SearchByTypeComponent,
    TabBottomLinkComponent,
    SearchResultsComponent,
    FiltersComponent,
    SearchResultListComponent,
    ShowroomComponent,
    HomeComponent,
    ShowroomBannerComponent,
    ShowroomModelsComponent,
    ShowroomMapComponent,
    ShowroomBrowseDealershipComponent,
    FindNewTodayComponent,
    HomeBannerComponent,
    SearchAllComponent,
    HomeBodyContentsComponent,
    SliderSpecialsComponent,
    SliderTrendingComponent,
    PopularSearchComponent,
    NewsReviewsComponent,
    WhyChooseComponent,
    PopularBrandsComponent,
    NewCarsDetailsComponent,
    NewCarBannerComponent,
    EnquiryFormComponent,
    NewCarsSliderComponent,
    UsedCarsDetailsComponent,
    UsedImageGalleryComponent,
    SliderSimilarUsedCarsComponent,
    SliderPeopleViewedComponent,
    EstimationCalculationComponent,
    FindDealerComponent,
    BrowseByDealershipComponent,
    DetailsComponent,
    ListingComponent,
    LoadingScreenComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    AppRoutingModule,
    Ng5SliderModule,
    IgxInputGroupModule,
    IgxSliderModule,
    MaterialModule,
    OwlModule,
    NgxGalleryModule,
    NgMultiSelectDropDownModule.forRoot()
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
