import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-estimation-calculation',
  templateUrl: './estimation-calculation.component.html',
  styleUrls: ['./estimation-calculation.component.scss']
})
export class EstimationCalculationComponent implements OnInit {

  deposit = 0;
  disabled = false;
  invert = 0;
  max = 0;
  min = 0;
  step = 0;
  thumbLabel = '';
  tickInterval = 0;
  vertical = 0;
  interest = 0;
  balloon = '';
  month = 0;

  constructor() { }

  ngOnInit() {
  }

}
