import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-slider-people-viewed',
  templateUrl: './slider-people-viewed.component.html',
  styleUrls: ['./slider-people-viewed.component.scss']
})
export class SliderPeopleViewedComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  title = "";

  carouselOptions = {
    margin: 25,
    nav: true,
    navText: [
      "<div class='nav-btn prev-slide'></div>",
      "<div class='nav-btn next-slide'></div>"
    ],
    responsiveClass: true,
    responsive: {
      0: {
        items: 1,
        nav: true
      },
      600: {
        items: 1,
        nav: true
      },
      678: {
        items: 3,
        nav: true
      },
      1000: {
        items: 4,
        nav: true,
        loop: false
      },
      1500: {
        items: 4,
        nav: true,
        loop: false
      }
    }
  };

  images = [
    {
      type: "new",
      text: "2019 Audi Q5 2.0 FSI S line",
      price: "R 780,200",
      bsize: "R 8,234 p/m x 72*",
      trans: "Manual",
      futype: "Petrol",
      multimg: false,
      image: "../../../assets/images/slider-pv-00.jpg",
      klm: "",
      isFav: false,
      isUsed: false
    },
    {
      type: "USED",
      text: "2016 Volkswagen Tiguan 2.0 TDI Trendline",
      price: "R 475,800",
      bsize: "R 4,634 p/m x 72*",
      trans: "Automatic",
      futype: "Diesel",
      multimg: false,
      image: "../../../assets/images/slider-pv-01.jpg",
      klm: "20 000 km",
      isFav: false,
      isUsed: true
    },
    {
      type: "new",
      text: "2019 Volkswagen Tiguan 1.8 TDI Comfortline",
      price: "R 678,250",
      bsize: "R 3,934 p/m x 72*",
      trans: "Manual",
      futype: "Diesel",
      multimg: true,
      multiimges:[
        {img:"../../../assets/images/slider-pv-03.jpg"},
        {img:"../../../assets/images/slider-pv-03.jpg"},
        {img:"../../../assets/images/slider-pv-03.jpg"},
        {img:"../../../assets/images/slider-pv-03.jpg"}
      ],
      image: "../../../assets/images/slider-pv-03.jpg",
      klm: "",
      isFav: true,
      isUsed: false
    },
    {
      type: "USED",
      text: "2016 Volkswagen Tiguan 2.0 TDI Trendline",
      price: "R 475,800",
      bsize: "R 4,634 p/m x 72*",
      trans: "Automatic",
      futype: "Diesel",
      multimg: false,
      image: "../../../assets/images/slider-pv-04.jpg",
      klm: "20 000 km",
      isFav: false,
      isUsed: true
    },
    {
      type: "new",
      text: "2019 Audi Q5 2.0 FSI S line",
      price: "R 780,200",
      bsize: "R 8,234 p/m x 72*",
      trans: "Manual",
      futype: "Petrol",
      multimg: false,
      image: "../../../assets/images/slider-pv-00.jpg",
      klm: "",
      isFav: false,
      isUsed: false
    },
    {
      type: "USED",
      text: "2016 Volkswagen Tiguan 2.0 TDI Trendline",
      price: "R 475,800",
      bsize: "R 4,634 p/m x 72*",
      trans: "Automatic",
      futype: "Diesel",
      multimg: false,
      image: "../../../assets/images/slider-pv-01.jpg",
      klm: "20 000 km",
      isFav: false,
      isUsed: true
    }
  ];

}
