import { Component, OnInit } from '@angular/core';
import { Output, EventEmitter } from '@angular/core'; 

@Component({
  selector: 'app-search-results',
  templateUrl: './search-results.component.html',
  styleUrls: ['./search-results.component.scss']
})
export class SearchResultsComponent implements OnInit {
  showFilter;
  showResults;
  
  constructor() {
    
   }
  
  ngOnInit() {
  }

  onSearchClick(data: any){
    this.showFilter = true;
    this.showResults= true;
  }

  onFilterClick(data: any) {
    this.showFilter = false;
    this.showResults = false;
  }

}
