import { Component, OnInit } from "@angular/core";
import { NgxGalleryOptions, NgxGalleryImage, NgxGalleryAnimation } from 'ngx-gallery';

@Component({
  selector: 'app-used-image-gallery',
  templateUrl: './used-image-gallery.component.html',
  styleUrls: ['./used-image-gallery.component.scss']
})
export class UsedImageGalleryComponent implements OnInit {

  galleryOptions: NgxGalleryOptions[];
  galleryImages: NgxGalleryImage[];

  constructor() {}

  ngOnInit(): void {
 
    this.galleryOptions = [
        {
            width: '100%',
            height: '520px',
            thumbnailsColumns: 7,
            imagePercent: 85,
            thumbnailsPercent: 15,
            imageAnimation: NgxGalleryAnimation.Slide
        },
        // max-width 800
        {
            breakpoint: 800,
            width: '100%',
            height: '500px',
            imagePercent: 90,
            thumbnailsPercent: 10,
        },
        // max-width 400
        {
          breakpoint: 767,
          width: '100%',
          height: '350px',
          imagePercent: 80,
          thumbnailsColumns: 5,
          thumbnailsPercent: 20,
          thumbnailsMargin: 2,
          thumbnailMargin: 2,
          preview: false
        }
    ];

    this.galleryImages = [
        {
            small: 'assets/images/img-showroom-car-6.png',
            medium: 'assets/images/img-showroom-car-6.png',
            big: 'assets/images/img-showroom-car-6.png'
        },
        {
          small: 'assets/images/img-showroom-car-2.png',
          medium: 'assets/images/img-showroom-car-2.png',
          big: 'assets/images/img-showroom-car-2.png'
        },
        {
          small: 'assets/images/img-showroom-car-3.png',
          medium: 'assets/images/img-showroom-car-3.png',
          big: 'assets/images/img-showroom-car-3.png'
        },
        {
          small: 'assets/images/img-showroom-car-4.png',
          medium: 'assets/images/img-showroom-car-4.png',
          big: 'assets/images/img-showroom-car-4.png'
      },
      {
        small: 'assets/images/img-showroom-car-5.png',
        medium: 'assets/images/img-showroom-car-5.png',
        big: 'assets/images/img-showroom-car-5.png'
      },
      {
        small: 'assets/images/img-showroom-car-1.png',
        medium: 'assets/images/img-showroom-car-1.png',
        big: 'assets/images/img-showroom-car-1.png'
      }
    ];
}
}