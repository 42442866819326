import { Component, OnInit } from '@angular/core';
import { HostListener } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  shownewCars: boolean;
  showusedCars: boolean;
  showspecialsCars: boolean;
  isCollapse = false;
  MobileNewTabNavigaation = false;
  MobileUsedTabNavigaation = false;
  MobileSpecialsTabNavigaation = false;
  MobileFootLinks = true;


  constructor(private router: Router) {
    this.shownewCars = false;
    this.showusedCars = false;
    this.showspecialsCars = false;
    this.isCollapse = false;
    this.MobileNewTabNavigaation = false;
    this.MobileUsedTabNavigaation = false;
    this.MobileSpecialsTabNavigaation = false;
    this.MobileFootLinks = true;

    this.router.events.subscribe((val) => {
      this.shownewCars = false;
      this.showusedCars = false;
      this.showspecialsCars = false;
      this.isCollapse = false;
      this.MobileNewTabNavigaation = false;
      this.MobileUsedTabNavigaation = false;
      this.MobileSpecialsTabNavigaation = false;
      this.MobileFootLinks = true;
      window.scrollTo(0, 0);
    });
  }

 @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    this.shownewCars = false;
    this.showusedCars = false;
    this.showspecialsCars = false;
  }

  ngOnInit() {

  }
  toggleNew() {
    this.showusedCars = false;
    this.showspecialsCars = false;
    this.shownewCars = !this.shownewCars;
  }
  toggleUsed() {
    this.shownewCars = false;
    this.showspecialsCars = false;
    this.showusedCars = !this.showusedCars;
  }
  toggleSpecials() {
    this.showusedCars = false;
    this.shownewCars = false;
    this.showspecialsCars = !this.showspecialsCars;
  }
  toggleNav() {
    this.isCollapse = !this.isCollapse;
    const body = document.getElementsByTagName('body')[0];
    body.classList.toggle('active');
  }

  NewtabNav() {
    this.MobileNewTabNavigaation = !this.MobileNewTabNavigaation;
    this.MobileUsedTabNavigaation = false;
    this.MobileSpecialsTabNavigaation = false;
    this.MobileFootLinks = !this.MobileFootLinks;
  }

  UsedtabNav() {
    this.MobileNewTabNavigaation = false;
    this.MobileUsedTabNavigaation = !this.MobileUsedTabNavigaation;
    this.MobileSpecialsTabNavigaation = false;
    this.MobileFootLinks = !this.MobileFootLinks;
  }

  SpecialstabNav() {
    this.MobileNewTabNavigaation = false;
    this.MobileUsedTabNavigaation = false;
    this.MobileSpecialsTabNavigaation = !this.MobileSpecialsTabNavigaation;
    this.MobileFootLinks = !this.MobileFootLinks;
  }

  onNewMobClick(data: any) {
    this.MobileNewTabNavigaation = false;
    this.MobileUsedTabNavigaation = false;
    this.MobileSpecialsTabNavigaation = false;
    this.MobileFootLinks = true;
  }


  onUsedMobClick(data: any) {
    this.MobileNewTabNavigaation = false;
    this.MobileUsedTabNavigaation = false;
    this.MobileSpecialsTabNavigaation = false;
    this.MobileFootLinks = true;
  }

  onSpecialsMobClick(data: any) {
    this.MobileNewTabNavigaation = false;
    this.MobileUsedTabNavigaation = false;
    this.MobileSpecialsTabNavigaation = false;
    this.MobileFootLinks = true;
  }

}
