import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { NewComponent } from '../../src/app/components/meganav/new/new.component';
import { UsedComponent } from '../../src/app/components/meganav/used/used.component';
import { SpecialsComponent } from '../../src/app/components/meganav/specials/specials.component';
import { SearchResultsComponent } from '../../src/app/components/search-results/search-results.component';
import { HomeComponent } from './components/home/home.component';
import { ShowroomComponent } from './components/showroom/showroom.component';
import { NewCarsDetailsComponent } from './components/new-cars-details/new-cars-details.component';
import { UsedCarsDetailsComponent } from './components/used-cars-details/used-cars-details.component';
import { FindDealerComponent } from './components/find-dealer/find-dealer.component';
import { DetailsComponent } from './components/find-dealer/details/details.component';
import { ListingComponent } from './components/find-dealer/listing/listing.component';
import { from } from 'rxjs';

const routes: Routes = [
  { path: '',
    component: HomeComponent
  },
  {
    path: 'brand',
    component: ShowroomComponent
  },
  {
    path: 'New',
    component:  NewComponent
  },
  {
    path: 'Used',
    component:  UsedComponent
  },
  {
    path: 'Specials',
    component:  SpecialsComponent
  },
  {
    path: 'search-results',
    component: SearchResultsComponent
  },
  {
    path: 'new-cars-details',
    component: NewCarsDetailsComponent
  },
  {
    path: 'used-car-details',
    component: UsedCarsDetailsComponent
  },
  {
    path: 'find-dealer',
    component: FindDealerComponent
  },
  {
    path: 'find-dealer/details',
    component: DetailsComponent
  },
  {
    path: 'find-dealer/listing',
    component: ListingComponent
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
