import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-listing',
  templateUrl: './listing.component.html',
  styleUrls: ['./listing.component.scss']
})
export class ListingComponent implements OnInit {
  status: boolean = false;
  showFilter;
  showResults;

  constructor() { }

  ngOnInit() {
  }

  
  clickEvent(){
      this.status = !this.status;      
      const body = document.getElementsByClassName('listing-top')[0];
      body.classList.toggle('minimised'); 
  }

  onSearchClick(data: any){
    this.showFilter = true;
    this.showResults= true;
  }

  onFilterClick(data: any) {
    this.showFilter = false;
    this.showResults = false;
  }

}
