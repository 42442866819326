export enum ProductCategory {
    All = 0,
    New = 1,
    Demo = 2,
    Used = 3
}

// To convert the enum into a standard array to build selects:
export function enumSelector(definition) {
    return Object.keys(definition)
      .map(key => ({ value: definition[key], title: key }));
}
