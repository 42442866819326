import { Component, OnInit, OnDestroy } from '@angular/core';

// rx.js
import { Subscription } from 'rxjs';

// services
import { LoadingScreenService } from 'src/app/services/loading-screen.service';

@Component({
  selector: 'app-loading-screen',
  templateUrl: './loading-screen.component.html',
  styleUrls: ['./loading-screen.component.scss']
})
export class LoadingScreenComponent implements OnInit, OnDestroy {

  // tslint:disable-next-line:no-inferrable-types
  loading: boolean = false;
  color = 'primary';
  mode = 'indeterminate';
  value = 100;
  loadingSubscription: Subscription;

  constructor(private loadingScreenService: LoadingScreenService) {
  }

  ngOnInit() {
    this.loadingSubscription = this.loadingScreenService.loadingStatus.subscribe((value: boolean) => {
    setTimeout(() => {
          this.loading = value;
      },0);
    });
  }

  ngOnDestroy() {
    this.loadingSubscription.unsubscribe();
  }
}
