import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

// rxjs
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';

// service
import { LoadingScreenService } from 'src/app/services/loading-screen.service';
import { HomeService } from 'src/app/services/home.service';

@Component({
  selector: 'app-popular-brands',
  templateUrl: './popular-brands.component.html',
  styleUrls: ['./popular-brands.component.scss']
})
export class PopularBrandsComponent implements OnInit {
  brands: any = [];
  dataSubscription: Subscription;
  constructor(private router: Router,
              private loadingScreenService: LoadingScreenService,
              private homeService: HomeService) {
                this.getList();
              }

  ngOnInit() {
  }

  getList() {
    this.loadingScreenService.show();
    this.dataSubscription = this.homeService.getMakeList()
      .pipe(first())
      .subscribe(
        (res: any) => {
          this.brands = res.result;
        },
        () => {
          this.loadingScreenService.hide();
        });
  }
}
