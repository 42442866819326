import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

// enums
import { ProductCategory } from 'src/app/core/enums/app-enum';

@Component({
  selector: 'app-find-new-today',
  templateUrl: './find-new-today.component.html',
  styleUrls: ['./find-new-today.component.scss']
})
export class FindNewTodayComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
  }

  onClickFindNewCar() {
    const query = {
      searchVehicleType: ProductCategory.New
    };
    this.router.navigate(['/search-results'], {queryParams: query });
  }
}
