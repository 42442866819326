import { Component, OnInit, EventEmitter, Output  } from '@angular/core';

@Component({
  selector: 'app-search-result-list',
  templateUrl: './search-result-list.component.html',
  styleUrls: ['./search-result-list.component.scss']
})
export class SearchResultListComponent implements OnInit {

  constructor() { }
  @Output()
  onSearchClick: EventEmitter<boolean> = new EventEmitter<boolean>();
  
  

  ngOnInit() {
  }

  showFilter(){
    this.onSearchClick.emit(true);
  }

  carouselOptions = {
    margin: 0,
    nav: true,
    navText: [
      "<div class='nav-btn prev-slide'></div>",
      "<div class='nav-btn next-slide'></div>"
    ],
    responsiveClass: true,
    responsive: {
      0: {
        items: 1,
        nav: true
      },
      600: {
        items: 1,
        nav: true
      },
      678: {
        items: 1,
        nav: true
      },
      1000: {
        items: 1,
        nav: true,
        loop: false
      },
      1500: {
        items: 1,
        nav: true,
        loop: false
      }
    }
  };
}
