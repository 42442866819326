import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SliderType } from 'igniteui-angular';

@Component({
  selector: 'app-showroom-models',
  templateUrl: './showroom-models.component.html',
  styleUrls: ['./showroom-models.component.scss']
})
export class ShowroomModelsComponent implements OnInit {
  searchModelsForm: FormGroup;
  public sliderType = SliderType;
  public priceRange: PriceRange = new PriceRange(0, 5000);

  public showroomCars = [
    {id: 1, carName: 'up!', price: 'R172,200', imgPath: '../../../../assets/images/showroom-cars/vw-up.png'},
    {id: 1, carName: 'New Polo Vivo', price: 'R189,400', imgPath: '../../../../assets/images/showroom-cars/vw-newpolovivo.png'},
    {id: 1, carName: 'New Polo Sedan', price: 'R213,000', imgPath: '../../../../assets/images/showroom-cars/vw-polosedan.png'},
    {id: 1, carName: 'New Polo', price: 'R245,500', imgPath: '../../../../assets/images/showroom-cars/vw-newpolo.png'},
    {id: 1, carName: 'Jetta', price: 'R356,400', imgPath: '../../../../assets/images/showroom-cars/vw-jetta.png'},
    {id: 1, carName: 'Golf', price: 'R316,200', imgPath: '../../../../assets/images/showroom-cars/vw-golf.png'},
    {id: 1, carName: 'Tiguan', price: 'R433,400', imgPath: '../../../../assets/images/showroom-cars/vw-tiguan.png'},
    {id: 1, carName: 'Tiguan Allspace', price: 'R462,400', imgPath: '../../../../assets/images/showroom-cars/vw-tiguanallspace.png'},
    {id: 1, carName: 'New Touareg', price: 'R1,024,800', imgPath: '../../../../assets/images/showroom-cars/vw-new-touareg.png'},
    {id: 1, carName: 'Amarok', price: 'R534,200', imgPath: '../../../../assets/images/showroom-cars/vw-amarok.png'},
    {id: 1, carName: 'Arteon', price: 'R599,900', imgPath: '../../../../assets/images/showroom-cars/vw-arteon.png'},
    {id: 1, carName: 'Caddy', price: 'R284,500', imgPath: '../../../../assets/images/showroom-cars/vw-caddy.png'},
    {id: 1, carName: 'Transporter', price: 'R512,200', imgPath: '../../../../assets/images/showroom-cars/vw-transporter.png'},
    {id: 1, carName: 'Komni', price: 'R562,700', imgPath: '../../../../assets/images/showroom-cars/vw-komni.png'},
    {id: 1, carName: 'Crafter', price: 'R519,900', imgPath: '../../../../assets/images/showroom-cars/vw-crafter.png'}
  ];

  constructor( private formBuilder: FormBuilder) { }

  public updatePriceRange(event) {
    const prevPriceRange = this.priceRange;
    switch (event.id) {
      case 'lowerInput': {
        if (!isNaN(parseInt(event.value, 100))) {
          this.priceRange = new PriceRange(event.value, prevPriceRange.upper);
        }
        break;
      }
      case 'upperInput': {
        if (!isNaN(parseInt(event.value, 100))) {
          this.priceRange = new PriceRange(prevPriceRange.lower, event.value);
        }
        break;
      }
    }
  }

  ngOnInit() {
    this.searchModelsForm = this.formBuilder.group({
      lowerInput: [],
      upperInput: [],
      slider: []
    });
  }

}

class PriceRange {
  constructor(
    public lower: number,
    public upper: number
  ) {

  }
}
