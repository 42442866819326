import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { map } from 'rxjs/operators';
import { IPageResponseModel } from '../models/ipage-response-model';
import { AppConfig } from '../app.config';

@Injectable({
  providedIn: 'root'
})
export class SearchResultService {

  constructor(private dataService: DataService) { }

  getSideBarFilters() {
    const request = {
      path: AppConfig.Api.SearchResult.SidebarFilteres
    };

    return this.dataService.get(request).pipe(
      map((res) =>  {
         return res;
        }
      ));
  }
}
