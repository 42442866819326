import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

// environments
import { environment } from 'src/environments/environment';

// rx.js
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(private http: HttpClient) { }

  post(req: any) {
    const url = `${environment.apiUrl}/${req.path}`;
    return this.http.post<any>(url, req.params);
  }

  postwithid(id: number, req: any) {
    const url = `${environment.apiUrl}/${req.path}/${id}`;
    return this.http.post<any>(url, req.params);
  }

  download(req: any) {
    const url = `${environment.apiUrl}/${req.path}`;
    return this.http.post<any>(url, req.params);
  }

  put(id: number, req: any) {
    const url = `${environment.apiUrl}/${req.path}/${id}`;
    return this.http.put<any>(url, req.params);
  }

  putwithstatus(id: number, status: number, req: any) {
    const url = `${environment.apiUrl}/${req.path}/${id}/${status}`;
    return this.http.put<any>(url, req.params);
  }

  delete(id: number, req: any) {
    const url = `${environment.apiUrl}/${req.path}/${id}`;
    return this.http.delete<any>(url);
  }

  getPageList<T>(req) {

    const url = `${environment.apiUrl}/${req.path}`;
    let params = new HttpParams();
    Object.keys(req.params).forEach(key => {
      if (req.params[key] != null) {
        params = params.append(key, req.params[key]);
      }
    });

    // tslint:disable-next-line:object-literal-shorthand
    return this.http.get<T>(url, { params: params }).pipe(
      map((resp) => resp as unknown as T));
  }

  getById<T>(id, req) {
    const url = `${environment.apiUrl}/${req.path}/${id}`;
     // tslint:disable-next-line:object-literal-shorthand
    return this.http.get<T>(url).pipe(
    map((resp) => resp as unknown as T));
  }

  getByIdQueryParam<T>(id, req) {
    let params = new HttpParams();
    params = params.append('id', id);

    const url = `${environment.apiUrl}/${req.path}`;
     // tslint:disable-next-line:object-literal-shorthand
    return this.http.get<T>(url, { params : params }).pipe(
    map((resp) => resp as unknown as T));
  }

  get(req: any) {
    const url = `${environment.apiUrl}/${req.path}`;
    return this.http.get<any>(url, req.params);
  }

  getmodel<T>(req: any) {
    const params = new HttpParams();

    const url = `${environment.apiUrl}/${req.path}`;
     // tslint:disable-next-line:object-literal-shorthand
    return this.http.get<T>(url, { params : params }).pipe(
    map((resp) => resp as unknown as T));
  }

  getMockData<T>(req: any) {
    const params = new HttpParams();

    const url = `${req.path}`;
     // tslint:disable-next-line:object-literal-shorthand
    return this.http.get<T>(url, { params : params }).pipe(
    map((resp) => resp as unknown as T));
  }

  getByParams(req: any) {
    const url = `${environment.apiUrl}/${req.path}`;
    let params = new HttpParams();
    Object.keys(req.params).forEach(key => {
      if (req.params[key] != null) {
          params = params.append(key, req.params[key]);
       }
     });

    return this.http.get<any>(url, { params });
  }
}
