import { Component, OnInit, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-specials',
  templateUrl: './specials.component.html',
  styleUrls: ['./specials.component.scss']
})
export class SpecialsComponent implements OnInit {
  hideOnClick = true;

  constructor() { 
    this.hideOnClick = true;
  }
  @Output()
  onSpecialsMobClick: EventEmitter<boolean> = new EventEmitter<boolean>();

  ngOnInit() {
  }

  backToNav() {
    this.hideOnClick = !this.hideOnClick;
    this.onSpecialsMobClick.emit(true);
  }

}
