import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-new-car-specials',
  templateUrl: './new-car-specials.component.html',
  styleUrls: ['./new-car-specials.component.scss']
})
export class NewCarSpecialsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
