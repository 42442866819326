export const AppConfig = {
    Api: {
        SearchResult: {
            SidebarFilteres: 'frontend/get-search-filters'
        },
        Home: {
            Specials: '',
            WhyChooseUs: 'frontend/why-choose-us',
            StockTypeList: 'frontend/stock-types/list',
            BodyTypeList: 'frontend/body-types/list',
            MakeList: 'frontend/vehicles/makes/list',
            ModelList: 'frontend/vehicles/models/list',
            Provinces: 'provinces/list'
        }
    }
};
