import { Component, OnInit, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-used',
  templateUrl: './used.component.html',
  styleUrls: ['./used.component.scss']
})
export class UsedComponent implements OnInit {

   showBrands = false;
   showTypes = false;
   showPrices = false;
   hideOnClick = true;

  constructor() { 
    this.showBrands = false;
    this.showTypes = false;
    this.showPrices = false;
    this.hideOnClick = true;
  }

  @Output()
  onUsedMobClick: EventEmitter<boolean> = new EventEmitter<boolean>();

  ngOnInit() {
  }

  showBrand() {
    this.showBrands = !this.showBrands;
    this.showTypes = false;
    this.showPrices = false;
    this.hideOnClick = !this.hideOnClick; 
  }

  showType() {
    this.showBrands = false;
    this.showTypes = !this.showTypes;
    this.showPrices = false;
    this.hideOnClick = !this.hideOnClick; 
  }

  showPrice() {
    this.showBrands = false;
    this.showTypes = false;
    this.showPrices = !this.showPrices;
    this.hideOnClick = !this.hideOnClick; 
  }

  backToNav() {
    this.hideOnClick = false;
    this.onUsedMobClick.emit(true);
  }

  backToUsedNav() {
    this.showBrands = false;
    this.showTypes = false;
    this.showPrices = false;
    this.hideOnClick = true;
  } 

}
