import { Component, OnInit } from '@angular/core';

// enums
import { ProductCategory } from 'src/app/core/enums/app-enum';

@Component({
  selector: 'app-home-banner',
  templateUrl: './home-banner.component.html',
  styleUrls: ['./home-banner.component.scss']
})
export class HomeBannerComponent implements OnInit {
  selectedVehicleType = ProductCategory.All;
  constructor() {

  }

  ngOnInit() {
  }

  onClickVehicleType(btnClick: any) {

    switch (btnClick) {
      case ProductCategory.All: {
        this.selectedVehicleType = ProductCategory.All;
        break;
      }
      case ProductCategory.New: {
        this.selectedVehicleType = ProductCategory.New;
        break;
      }
      case ProductCategory.Used: {
        this.selectedVehicleType = ProductCategory.Used;
        break;
      }
      case ProductCategory.Demo: {
        this.selectedVehicleType = ProductCategory.Demo;
        break;
      }
    }
  }

}
