import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';

// rxjs
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';

// enums
import { ProductCategory } from 'src/app/core/enums/app-enum';

// Services
import { HomeService } from 'src/app/services/home.service';
import { LoadingScreenService } from 'src/app/services/loading-screen.service';

@Component({
  selector: 'app-search-all',
  templateUrl: './search-all.component.html',
  styleUrls: ['./search-all.component.scss']
})

export class SearchAllComponent implements OnInit {

  multiSelectDropdownSettings = {};
  singleSelectDropdownSettings = {};
  searchAllForm: FormGroup;
  searchVehicleType: any;
  dataSubscription: Subscription;
  provinces = [];
  makes = [];
  models = [];
  bodyTypes = [];
  minPrices = [
    { id: 1, name: '10000' },
    { id: 2, name: '20000' },
    { id: 3, name: '30000' },
    { id: 4, name: '40000' },
    { id: 5, name: '50000' }
  ];
  maxPrices = [
    { id: 1, name: '100000' },
    { id: 2, name: '200000' },
    { id: 3, name: '300000' },
    { id: 4, name: '400000' },
    { id: 5, name: '500000' }
  ];

  constructor(private formBuilder: FormBuilder,
              private router: Router,
              private loadingScreenService: LoadingScreenService,
              private homeService: HomeService) {
                this.getDropDownData();
              }

  @Input()
  public set SearchVehicleType(value: any) {
    if (!value) {
      this.searchVehicleType = ProductCategory.All;
    } else {
      this.searchVehicleType = value;
    }
  }

  ngOnInit() {
    this.searchAllForm = this.formBuilder.group({
      Make: [''],
      Model: [''],
      BodyType: [''],
      MinPrice: [''],
      MaxPrice: [''],
      Province: ['']
    });

    this.multiSelectDropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true
    };

    this.singleSelectDropdownSettings = {
      singleSelection: true,
      closeDropDownOnSelection: true,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true
    };
  }

  getDropDownData() {
    this.loadingScreenService.show();

    this.dataSubscription = this.homeService.getBodyTypeList()
      .pipe(first())
      .subscribe(
        (res: any) => {
          this.bodyTypes = res.result;
        },
        () => {
          this.loadingScreenService.hide();
        });

    this.dataSubscription = this.homeService.getMakeList()
      .pipe(first())
      .subscribe(
        (res: any) => {
          this.makes = res.result;
        },
        () => {
          this.loadingScreenService.hide();
        });

    this.dataSubscription = this.homeService.getModelList()
      .pipe(first())
      .subscribe(
        (res: any) => {
          this.models = res.result;
        },
        () => {
          this.loadingScreenService.hide();
        });

    this.dataSubscription = this.homeService.getProvinces()
      .pipe(first())
      .subscribe(
        (res: any) => {
          this.provinces = res.result;
        },
        () => {
          this.loadingScreenService.hide();
        });
  }

  onClickBrowse() {
    let bodyType = '';
    let make = '';
    let maxPrice = '';
    let minPrice = '';
    let model = '';
    let province = '';
    const result = Object.assign({}, this.searchAllForm.value);
    if (result) {
      if (result.BodyType) {
        result.BodyType.forEach(element => {
          if (bodyType.length > 0) {
            bodyType += ',';
          }
          bodyType += element.id;
        });
      }
      if (result.Make && result.Make[0]) {
        make = result.Make[0].id;
      }
      if (result.MaxPrice && result.MaxPrice[0]) {
        maxPrice = result.MaxPrice[0].name;
      }
      if (result.MinPrice && result.MinPrice[0]) {
        minPrice = result.MinPrice[0].name;
      }
      if (result.Model && result.Model[0]) {
        model = result.Model[0].id;
      }
      if (result.Province && result.Province[0]) {
        province = result.Province[0].id;
      }
    }
    const query = {
      searchVehicleType: this.searchVehicleType,
      make,
      model,
      bodyType,
      minPrice,
      maxPrice,
      province
    };
    this.router.navigate(['/search-results'], {queryParams: query });
  }

}
